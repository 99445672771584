import styled from "styled-components"
import { device } from "breakpoints"
import { Link } from "gatsby"

export const HomeQuickLinksContainer = styled.ul`
  /* display: flex; */
  /* flex-direction: column; */
  list-style: none;
  margin-top: 35px;

  @media ${device.tablet} {
    margin-bottom: 80px;
  }
  @media ${device.desktop} {
    margin-bottom: 120px;
  }
  li {
    margin-bottom: 27px;

    @media ${device.desktop} {
      margin-bottom: 35px;
      margin-top: 40px;
    }
  }

  p {
    font-size: 18px;
    margin-top: 5px;
    line-height: 140%;
    @media ${device.desktop} {
      font-size: 20px;
      margin-top: 9px;
      line-height: 100%;
    }

    > svg {
      display: none;
      @media ${device.desktop} {
        display: inline-block;
        height: 17px;
        width: 17px;
        margin-right: 2px;
        position: relative;
        top: 1px;
      }
    }
  }
`

export const HighlightedLink = styled(Link)`
  font-size: 24px;
  font-weight: 600;
  line-height: 170%;
  background: ${props => props.theme[props.color][0]};
  transition: 90ms;
  padding: 5px 0px;
  @media ${device.desktop} {
    font-size: 30px;
    padding: 8px 0px;
  }

  :hover {
    background: ${props => props.theme[props.color][2]};
  }

  span {
    background-image: linear-gradient(to right, black, black);
    background-position: 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 2px;
  }

  > svg {
    height: 12px;
    width: 12px;
    margin-left: 5px;
    margin-right: 6px;

    @media ${device.desktop} {
      height: 18px;
      width: 18px;
      margin-left: 9px;
    }
  }
`

export const HeroImg = styled.div`
  display: block;
  margin-top: 80px;
  margin-bottom: 100px;

  @media ${device.tablet} {
    display: none;
  }
`
