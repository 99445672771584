import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { HomeQuickLinks } from "components/HomeQuickLinks"

import { MainColumn } from "components/MainColumn"

const IndexPage = ({ pageContext }) => {
  const quickLinks = pageContext?.homePageSections
  const homeHeader = pageContext?.homePageSections?.homeheader

  return (
    <Layout pageContext={pageContext} home>
      <SEO title="Home" />
      <MainColumn>
        {quickLinks && <HomeQuickLinks data={quickLinks} />}
      </MainColumn>
    </Layout>
  )
}

export default IndexPage
